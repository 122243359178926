// import { React, Component, } from 'react';
// import { Card, CardGroup, Navbar, Container, Nav, Button } from 'react-bootstrap';
// import { Authenticator } from '@aws-amplify/ui-react';

// import tankAlmostFull from '../assets/Tank_87_pc.png'
// import tankHalfFull from '../assets/Tank_44_pc.png'
// import tankAlmostEmpty from '../assets/Tank_15_pc.png'
//
// class CardsNew extends Component {
//     constructor(props){
//         super(props)
//         this.state = {
//             frontPaddockPercentage : 0, 
//             sideDamWaterLevel : 0x00,
//             time: new Date().toLocaleTimeString(),
//         }
//         this.makeTimer()
//     }
      
//     makeTimer(){
//         setInterval(() => {
//         let frontPaddockPercentageRand = Math.floor(Math.random() * 100);
//         let sideDamWaterLevelRand = Math.floor(Math.random() * 100);
//         let currentTime = new Date().toLocaleTimeString();
//         this.setState({frontPaddockPercentage: frontPaddockPercentageRand});
//         this.setState({sideDamWaterLevel: sideDamWaterLevelRand});
//         this.setState({time: currentTime});
//         }, 2500)
//     }
//     render() {
//         return (
//             <Authenticator>
//                 {({ signOut}) => (
//                     <main>
//                         <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
//                             <Container>
//                                 <Navbar.Brand href="#">SenseMyFarm</Navbar.Brand>
//                                 <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//                                 <Navbar.Collapse id="responsive-navbar-nav">
//                                     <Nav className="me-auto">
//                                         <Nav.Link href="#">Home</Nav.Link>
//                                         <Nav.Link href="#">Sensors</Nav.Link>
//                                     </Nav>
//                                     <Nav>
//                                         <Button onClick={signOut} variant="outline-danger">Logout</Button>    
//                                     </Nav>
//                                 </Navbar.Collapse>
//                             </Container>
//                         </Navbar>
//                         <br />
//                         <Container className="container-fluid">
//                             <CardGroup>
//                                 <Card>
//                                     <Card.Img variant="top" src={tankAlmostFull} className="overflow" />
//                                     <Card.Body>
//                                         <Card.Title><p>{this.state.frontPaddockPercentage}%</p></Card.Title>
//                                         <Card.Subtitle>Front Paddock</Card.Subtitle>
//                                     </Card.Body>
//                                     <Card.Footer>
//                                     <small className="text-muted">Updated at {this.state.time}</small>
//                                     </Card.Footer>
//                                 </Card>
//                                 <Card>
//                                     <Card.Img variant="top" src={tankHalfFull} />
//                                     <Card.Body>
//                                         <Card.Title><p>{this.state.sideDamWaterLevel}%</p></Card.Title>
//                                         <Card.Subtitle>Side Dam</Card.Subtitle>
//                                     </Card.Body>
//                                     <Card.Footer>
//                                     <small className="text-muted">Updated at {this.state.time}</small>
//                                     </Card.Footer>
//                                 </Card>
//                                 <Card border='warning' style={{border: "2px solid"}}>
//                                     <Card.Img variant="top" src={tankAlmostEmpty} />
//                                     <Card.Body>
//                                         <Card.Title className='text-warning'>15%</Card.Title>
//                                         <Card.Subtitle>House Tank</Card.Subtitle>
//                                     </Card.Body>
                                    // <Card.Footer>
                                    // <small className="text-muted">Updated 10 minutes ago</small>
                                    // </Card.Footer>
//                                 </Card>
//                             </CardGroup>
//                             <br />
//                         </Container>
//                     </main>
                    
//                 )}
//             </Authenticator>
//           );
//     }
// }

// export default CardsNew;

import { Navbar, Button, Container, Nav, Card, } from 'react-bootstrap';
import { React, Component, } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';

class CardsNew extends Component {
    state = {
        isLoading: false,
        sensorData: [] 
    }

    async componentDidMount(){
        const response = await fetch('https://lr8yluyuhh.execute-api.ap-southeast-2.amazonaws.com/dev')
        const body = await response.json();
        this.setState({sensorData: body, isLoading: false});
    }

    render() {
        const isLoading = this.state.isLoading;
        const allSensorData = this.state.sensorData;

        if (isLoading) {
            return(
                <div>Pulling data...</div>
            );
        }

        let sensorData = allSensorData.map( sensorData =>
            <div key={sensorData.record_time}>
                <Card>
                    <Card.Body>
                        <Card.Title>Depth: {sensorData.raw_depth}</Card.Title>
                        <Card.Subtitle>Device: {sensorData.device_eui}</Card.Subtitle>
                        <Card.Text>Battery: {sensorData.device_battery} mV</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Updated at {sensorData.record_time}</small>
                    </Card.Footer>
                </Card>
                <br/>
            </div>
        )

        return (
            <Authenticator>
                {({signOut}) => (
                    <main>
                        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                            <Container>
                                <Navbar.Brand href="#">SenseMyFarm</Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link href="#">Home</Nav.Link>
                                        <Nav.Link href="#">Sensors</Nav.Link>
                                    </Nav>
                                    <Nav>
                                        <Button onClick={signOut} variant="outline-danger">Logout</Button>    
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        <br />
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h4>Sensor Data</h4>
                                </div>
                            </div>
                            <br/>
                            {/* <Row>
                                {Array.from({ length: 8 }).map((_, idx) => (
                                    <Col>
                                        {this.state.sensorData.length === 0 ? <p>No data available</p>: sensorData}
                                    </Col>
                                ))}
                            </Row> */}
                            <div>
                                {this.state.sensorData.length === 0 ? <p>No data available</p>: sensorData}
                            </div>
                        </div>
                    </main>
                    
                )}
            </Authenticator>
            
        )
    }
}

export default CardsNew;